import React, { useState } from 'react';
import Rating from '../Rating';

export default function Testimonial() {
  const [activeTab, setActiveTab] = useState(2);
  const handleTabClick = tabNumber => {
    setActiveTab(tabNumber);
  };
  return (
    <div className="cs_tabs cs_style1">
      <ul className="cs_tab_links">
        <li className={activeTab === 1 ? 'active' : ''}>
          <div className="cs_tab_link_in" onClick={() => handleTabClick(1)}>
            <div className="cs_testimonial_1_avatar">
              <img src="/images/home_1/avatar_1.png" alt="Avatar" />
              <div className="cs_testimonial_1_avatar_right">
                <h3 className="cs_fs_24 cs_semibold mb-0">Nguyễn Trung Tín</h3>
                <p className="cs_heading_color mb-0">Gò Vấp, HCM</p>
              </div>
            </div>
          </div>
        </li>
        <li className={activeTab === 2 ? 'active' : ''}>
          <div className="cs_tab_link_in" onClick={() => handleTabClick(2)}>
            <div className="cs_testimonial_1_avatar">
              <img src="/images/home_1/avatar_2.png" alt="Avatar" />
              <div className="cs_testimonial_1_avatar_right">
                <h3 className="cs_fs_24 cs_semibold mb-0">Nguyễn Kim Vân</h3>
                <p className="cs_heading_color mb-0">Quận 8, HCM</p>
              </div>
            </div>
          </div>
        </li>
        <li className={activeTab === 3 ? 'active' : ''}>
          <div className="cs_tab_link_in" onClick={() => handleTabClick(3)}>
            <div className="cs_testimonial_1_avatar">
              <img src="/images/home_1/avatar_3.png" alt="Avatar" />
              <div className="cs_testimonial_1_avatar_right">
                <h3 className="cs_fs_24 cs_semibold mb-0">Bùi Lê Ánh Vân</h3>
                <p className="cs_heading_color mb-0">Quận 5, HCM</p>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div className="cs_tab_body">
        {activeTab === 1 && (
          <div className="cs_testimonial cs_style_1">
            <img src="/images/icons/quote.svg" alt="Icon" />
            <p>
            Gần đây tôi phải đưa con mình đến Yuumi vì một vết thương nhẹ và tôi rất ấn tượng với sự chăm sóc mà con nhận được. Bác sĩ nhi khoa rất tuyệt vời với con tôi và khiến con tôi cảm thấy thoải mái, và toàn bộ nhân viên đều tử tế và chu đáo.
            </p>
            <Rating ratingNumber={5} />
          </div>
        )}
        {activeTab === 2 && (
          <div className="cs_testimonial cs_style_1">
            <img src="/images/icons/quote.svg" alt="Icon" />
            <p>
            Tôi hay bị đau đầu gần đây và vô cùng lo lắng khi tra cứu sức khỏe mình trên mạng. Nhưng nhờ có AI của Yuumi tôi hiểu rõ hơn về triệu chứng của mình và cách khắc phục mà không cần đến bệnh viện.
            </p>
            <Rating ratingNumber={4.5} />
          </div>
        )}
        {activeTab === 3 && (
          <div className="cs_testimonial cs_style_1">
            <img src="/images/icons/quote.svg" alt="Icon" />
            <p>
            Mỗi lần đi khám bệnh là tôi rất sợ vì mất thời gian vô cùng. Nhưng nhờ có Yuumi tôi tiết kiệm được một khoảng thời gian vô cùng lớn cho việc kinh doanh của mình. Cảm ơn Yuumi.
            </p>
            <Rating ratingNumber={4.5} />
          </div>
        )}
      </div>
    </div>
  );
}
