import React from 'react';
import BannerSectionStyle3 from '../Section/BannerSection/BannerSectionStyle3';
import BannerSectionStyle4 from '../Section/BannerSection/BannerSectionStyle4';
import Section from '../Section';
import DepartmentSectionStyle2 from '../Section/DepartmentSection/DepartmentSectionStyle2';
import FeaturesSectionStyle2 from '../Section/FeaturesSection/FeaturesSectionStyle2';
import FunFactSection from '../Section/FunFactSection';
import TeamSection from '../Section/TeamSection';
import GallerySection from '../Section/GallerySection';
import { pageTitle } from '../../helpers/PageTitle';
const departmentData = [
  {
    title: 'Gói kiểm tra tổng quát',
    subTitle:
      'Xét nghiệm máu, chụp CT cắt lớp và các xét nghiệm khác để chẩn đoán tình trạng sức khỏe',
    iconUrl: '/images/icons/calendar_white.svg',
    href: '/departments',
  },
  {
    title: 'Gói dịch vụ phục hồi chức năng',
    subTitle:
      'Vật lý trị liệu, dụng cụ trị liệu, điện trị liệu và các dịch vụ khác để giúp bệnh nhân phục hồi sau chấn thương',
    iconUrl: '/images/icons/calendar_white.svg',
    href: '/departments',
  },
  {
    title: 'Gói khám định kỳ',
    subTitle:
      'Khám sức khỏe định kỳ hàng năm, tiêm chủng và khám sức khỏe',
    iconUrl: '/images/icons/calendar_white.svg',
    href: '/departments',
  },
  {
    title: 'Gói điều trị bệnh cấp tính và mãn tính',
    subTitle:
      'Quản lý thuốc, quản lý bệnh tật và các phương pháp điều trị khác để cải thiện kết quả sức khỏe',
    iconUrl: '/images/icons/calendar_white.svg',
    href: '/departments',
  },
  {
    title: 'Gói hỗ trợ tâm lý',
    subTitle:
      'Tư vấn, trị liệu và các dịch vụ khác để giúp bệnh nhân quản lý tình trạng sức khỏe tinh thần.',
    iconUrl: '/images/icons/calendar_white.svg',
    href: '/departments',
  },
];

const featureListData = [
  {
    title: 'Chuyên gia y tế giàu kinh nghiệm',
    subTitle:
      'Đội ngũ của chúng tôi bao gồm các bác sĩ, y tá giàu kinh nghiệm và các chuyên gia chăm sóc sức khỏe khác, những người tận tâm cung cấp dịch vụ chăm sóc tốt nhất có thể cho bệnh nhân của chúng tôi.',
    iconUrl: 'images/icons/professional.svg',
  },
  {
    title: 'Đa dạng lựa chọn',
    subTitle:
      'Chúng tôi cung cấp nhiều loại dịch vụ chăm sóc sức khỏe, từ chăm sóc phòng ngừa đến điều trị chuyên biệt cho các tình trạng phức tạp.',
    iconUrl: 'images/icons/comprehensive.svg',
  },
  {
    title: 'Khách hàng là cốt lõi',
    subTitle:
      'Chúng tôi tin tưởng vào việc đối xử với mỗi bệnh nhân như một cá nhân và chúng tôi dành thời gian để hiểu những nhu cầu và mối quan tâm về sức khỏe riêng của bạn.',
    iconUrl: 'images/icons/patient.svg',
  },
  {
    title: 'Cơ sở vật chất hiện đại',
    subTitle:
      'Trung tâm chăm sóc sức khỏe của chúng tôi được trang bị công nghệ và thiết bị mới nhất để cung cấp cho bệnh nhân dịch vụ chăm sóc tiên tiến nhất có thể.',
    iconUrl: 'images/icons/facilities.svg',
  },
];

const funFactData = [
  { number: '20+', title: 'Năm kinh nghiệm' },
  { number: '95%', title: ' Đánh giá hài lòng từ bệnh nhân' },
  { number: '5000+', title: 'Bệnh nhân được phục vụ hằng năm' },
  { number: '80+', title: 'Nhân sự phân bổ tại các điểm khám' },
  { number: '15+', title: 'Vị trí thuận lợi trong khu vực TPHCM' },
];

const teamData = [
  {
    imgUrl: 'images/about/doctor_1.png',
    name: 'Bác sĩ Đăng Khoa',
    designation: 'Trưởng Khoa Tim Mạch',
    description:'Với chuyên môn trong việc quản lý các bệnh tim phức tạp và thực hiện các thủ thuật tim mạch tiên tiến',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
  },
  {
    imgUrl: 'images/about/doctor_2.png',
    name: 'Bác sĩ Mạnh Hùng',
    designation: 'Bác sĩ cấp cứu',
    description:
      'Với chuyên môn về điều trị các bệnh cấp tính và chấn thương của bác sĩ y khoa',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
  },
  {
    imgUrl: 'images/about/doctor_3.png',
    name: 'Bác sĩ Anh Thy',
    designation: 'Bác sĩ Nội - Tổng quát',
    description:
      'Có kinh nghiệm xử trí các bệnh lý phức tạp liên quan đến nội khoa.',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
  },
];

const galleryData = [
  { imgUrl: '/images/about/portfolio_1_lg.jpeg' },
  { imgUrl: '/images/about/portfolio_2_lg.jpeg' },
  { imgUrl: '/images/about/portfolio_3_lg.jpeg' },
  { imgUrl: '/images/about/portfolio_4_lg.jpeg' },
  { imgUrl: '/images/about/portfolio_5_lg.jpeg' },
];
/*const awardData = [
  {
    iconUrl: '/images/icons/award.svg',
    title: 'Malcolm Baldrige National Quality Award',
  },
  { iconUrl: '/images/icons/award.svg', title: 'HIMSS Davies Award' },
  {
    iconUrl: '/images/icons/award.svg',
    title: 'Healthgrades National’s Best Hospital',
  },
  {
    iconUrl: '/images/icons/award.svg',
    title: 'Joint Commission Gold Seal of Approval',
  },
];*/

export default function About() {
  pageTitle('About');
  return (
    <>
      <BannerSectionStyle3
        bgUrl="/images/about/banner_bg.svg"
        imgUrl="/images/about/banner_img.png"
        title="Chào mừng bạn đến với <br />Trợ lý sức khỏe <br/> thông minh Yuumi"
        subTitle="Trợ thủ đắc lực của Y tế và Sức khỏe"
      />
      <Section topMd={200} topLg={150} topXl={110}>
        <DepartmentSectionStyle2
          sectionTitle="Cung cấp các dịch vụ tốt nhất"
          sectionTitleUp="DỊCH VỤ"
          data={departmentData}
        />
      </Section>
      <Section topMd={175} topLg={125} topXl={85} bottomMd={100} bottomLg={110}>
        <FeaturesSectionStyle2
          sectionTitle="VÌ SAO CHỌN YUUMI"
          imgUrl="images/about/why_choose_us.jpeg"
          data={featureListData}
        />
      </Section>
      <Section>
        <FunFactSection
          bgUrl="images/about/fun_fact_bg.jpeg"
          data={funFactData}
        />
      </Section>
      <Section topMd={190} topLg={145} topXl={105}>
        <TeamSection
          sectionTitle="Bác Sĩ Chuyên Môn"
          sectionTitleUp="CÙNG GẶP"
          data={teamData}
        />
      </Section>
      <Section topMd={170} topLg={120} topXl={80}>
        <GallerySection
          sectionTitle="Cơ sở vật chất của chúng tôi và <br />Hoạt động mới nhất"
          sectionTitleUp="CÙNG XEM"
          data={galleryData}
        />
      </Section>
      <Section
        topMd={190}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
       { /* <AwardSectionStyle2
          sectionTitle="Winning Awards and <br />Recognition"
          sectionTitleUp="AWARDS"
          sectionSubTitle="We have been recognized for our commitment to <br />excellence in healthcare."
          data={awardData}
  />*/}
      </Section>
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle4
          bgUrl="images/about/banner_bg_2.jpeg"
          title="VÌ MỘT VIỆT NAM AN TÂM,<br/> KHOẺ MẠNH!"
          subTitle="Lên lịch với một trong những người có kinh nghiệm của chúng tôi ngay hôm nay."
          center
        />
      </Section>
    </>
  );
}
