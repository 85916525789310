import React from 'react';
import Hero from '../Hero';
import AboutSection from '../Section/AboutSection';
import BrandsSection from '../Section/BrandsSection';
import Banner from '../Section/BannerSection';
import Section from '../Section';
import FeaturesSection from '../Section/FeaturesSection';
import TestimonialSection from '../Section/TestimonialSection';
import BlogSection from '../Section/BlogSection';
import AppointmentSection from '../Section/AppointmentSection';
import FaqSection from '../Section/FaqSection';
import DepartmentSection from '../Section/DepartmentSection';
import { pageTitle } from '../../helpers/PageTitle';
const featureListData = [
  {
    iconSrc: '/images/home_1/compassion.svg',
    title: 'Thấu Hiểu',
    subTitle:
      'Chúng tôi hiểu rằng việc tìm kiếm dịch vụ chăm sóc y tế có thể là một trải nghiệm căng thẳng và đầy những trở ngại khác nhau.Chính vì vậy, chúng tôi luôn cố gắng tạo ra một môi trường thân thiện và hỗ trợ để giúp bệnh nhân và mọi người cảm thấy thoải mái.',
  },
  {
    iconSrc: '/images/home_1/excellence.svg',
    title: 'Hoàn Hảo',
    subTitle:
      'Chúng tôi cam kết cung cấp dịch vụ và chăm sóc y tế tuyệt vời cho bệnh nhân của mình. Chúng tôi tin tưởng vào việc liên tục nâng cao kỹ năng, kiến thức và nguồn lực của mình để đảm bảo rằng chúng tôi cung cấp dịch vụ chăm sóc có chất lượng cao nhất có thể.',
  },
  {
    iconSrc: '/images/home_1/integrity.svg',
    title: 'Chính Trực',
    subTitle: `Chúng tôi tin vào việc hành nghề y với sự liêm chính và trung thực. Chúng tôi minh bạch trong quá trình giao tiếp và đưa ra quyết định, đồng thời chúng tôi luôn đặt lợi ích của bệnh nhân lên hàng đầu và cung cấp giải pháp tốt nhất.`,
  },
  {
    iconSrc: '/images/home_1/respect.svg',
    title: 'Tôn Trọng',
    subTitle:
      'Chúng tôi đối xử với tất cả các cá nhân bằng sự tôn trọng và phẩm giá, bất kể nền tảng, niềm tin hoặc hoàn cảnh của họ. Chúng tôi tin rằng mỗi người đều xứng đáng được đối xử bằng lòng nhân ái và lòng tốt.',
  },
  {
    iconSrc: '/images/home_1/teamwork.svg',
    title: 'Đồng Lòng',
    subTitle:
      'Chúng tôi tin tưởng vào việc hợp tác làm việc với các thành viên trong nhóm của chúng tôi và các chuyên gia chăm sóc sức khỏe khác để cung cấp dịch vụ chăm sóc toàn diện và hiệu quả cho bệnh nhân của chúng tôi.',
  },
];
const brandData = [
  { imgUrl: 'images/brand_1.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_2.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_3.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_4.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_5.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_6.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_7.png', imgAlt: 'Brand' },
  { imgUrl: 'images/brand_8.png', imgAlt: 'Brand' },
];
const faqData = [
  {
    title: 'Yuumi cung cấp những dịch vụ gì?',
    content:
      'Yuumi cung cấp nhiều loại gói khám chuyên sâu đặc biệt phù hợp với nhu cầu cá nhân, cùng với vô vàn ưu đãi giúp bạn vừa an tâm vừa khỏe mạnh.',
  },
  {
    title: 'Làm thế nào để đặt lịch với Yuumi ?',
    content:
      'Bạn có thể đặt lịch trực tiếp trên trang website hoặc thông qua sự hướng dẫn của trợ lý Yuumi.',
  },
  {
    title: 'Yuumi có chấp nhận bảo hiểm không?',
    content:
      'Có. Khi đặt lịch khám và chọn dịch vụ y tế qua Yuumi, bạn sẽ có tùy chọn nhập thông tin bảo hiểm của mình. Hệ thống của chúng tôi sẽ xác minh thông tin bảo hiểm và tính toán phần chi phí được bảo hiểm chi trả. Người dùng có thể thấy rõ phần chi phí được bảo hiểm chi trả và phần còn lại mà họ phải thanh toán trực tiếp qua ứng dụng Yuumi, giúp quá trình thanh toán trở nên minh bạch và dễ dàng hơn.',
  },
  {
    title: 'Tôi nên chuẩn bị những gì khi đến khám?',
    content:
    <div>
    <p>1. Kiểm tra lại lịch hẹn của bạn trên ứng dụng Yuumi: Để đảm bảo không có thay đổi hoặc nhầm lẫn về thời gian và địa điểm. Yuumi cũng cho phép bạn thay đổi hoặc hủy lịch hẹn một cách dễ dàng nếu cần thiết.</p>
    <br/>
    <br/>
    <p>2. Mang theo thẻ bảo hiểm và giấy tờ tùy thân: Chứng minh nhân dân, căn cước công dân hoặc hộ chiếu để xác nhận danh tính. Điều này giúp bác sĩ và cơ sở y tế kiểm tra và cập nhật thông tin của bạn một cách chính xác trên hệ thống Yuumi.</p>
    <br/>
    <br/>
    <p>3. Hồ sơ bệnh án điện tử: Nếu bạn đã từng khám bệnh trước đó, hãy đảm bảo rằng các hồ sơ bệnh án, kết quả xét nghiệm, đơn thuốc cũ đều đã được tải lên và cập nhật trên hệ thống Yuumi. Điều này giúp bác sĩ nhanh chóng nắm bắt được tình trạng sức khỏe của bạn mà không cần bạn mang theo nhiều giấy tờ.</p>
    <br/>
    <br/>
    <p>4. Chuẩn bị cho các xét nghiệm: Nếu bạn được yêu cầu làm các xét nghiệm đặc biệt, Yuumi sẽ gửi thông báo và hướng dẫn chuẩn bị chi tiết. Làm theo các hướng dẫn này để đảm bảo kết quả xét nghiệm chính xác và buổi khám diễn ra suôn sẻ.</p>
</div>
  },
  {
    title: 'Làm cách nào để yêu cầu mua thêm thuốc theo toa?',
    content:
      'Yuumi có hỗ trợ giao thuốc tận nhà theo toa theo yêu cầu của khách hàng trong 2 giờ. Các bác sĩ đều trả kết quả khám bệnh và đơn thuốc qua Yuumi nên bạn có thể hoàn toàn đặt giao hàng đơn thuốc của bạn đến tận nhà qua Yuumi.'
  },
];
const blogData = [
  {
    title: 'Tầm quan trọng của sức khỏe tâm thần: Hiểu và quản lý rối loạn lo âu',
    thumbUrl: 'images/home_1/post_1.jpeg',
    date: 'Tháng 5, 2024',
    btnText: 'Đọc Thêm',
    href: '/blog/blog-details',
    socialShare: true,
  },
  {
    title: 'Ăn uống lành mạnh với mức tiết kiệm: Lời khuyên và chiến lược',
    thumbUrl: 'images/home_1/post_2.jpeg',
    date: 'Tháng 5, 2024',
    btnText: 'Đọc Thêm',
    href: '/blog/blog-details',
    socialShare: true,
  },
  {
    title: 'Tầm quan trọng của việc sàng lọc ung thư thường xuyên và phát hiện sớm',
    thumbUrl: 'images/home_1/post_3.jpeg',
    date: 'Tháng 5, 2024',
    btnText: 'Đọc Thêm',
    href: '/blog/blog-details',
    socialShare: true,
  },
];
/*const awardData = [
  {
    title: 'Malcolm Baldrige National Quality Award',
    subTitle:
      'This award recognizes healthcare organizations that have demonstrated excellence in leadership, strategic planning, customer and employee satisfaction, and operational efficiency.',
    iconUrl: 'images/icons/award.svg',
  },
  {
    title: 'HIMSS Davies Award',
    subTitle:
      'This award recognizes healthcare organizations that have used health information technology to improve patient outcomes and reduce costs.',
    iconUrl: 'images/icons/award.svg',
  },
  {
    title: 'Healthgrades National’s Best Hospital',
    subTitle:
      'This recognition is given to hospitals that have achieved high ratings for clinical quality and patient safety across multiple specialties and procedures.',
    iconUrl: 'images/icons/award.svg',
  },
  {
    title: 'Joint Commission Gold Seal of Approval',
    subTitle:
      'This recognition is given to hospitals that have met rigorous standards for patient safety and quality of care.',
    iconUrl: 'images/icons/award.svg',
  },
];*/
const departmentData = [
  {
    title: 'Khoa cấp cứu',
    iconUrl: 'images/home_1/department_icon_1.svg',
    href: '/departments',
  },
  {
    title: 'Khoa Nhi',
    iconUrl: 'images/home_1/department_icon_2.svg',
    href: '/departments',
  },
  {
    title: 'Phụ khoa',
    iconUrl: 'images/home_1/department_icon_3.svg',
    href: '/departments',
  },
  {
    title: 'Khoa tim mạch',
    iconUrl: 'images/home_1/department_icon_4.svg',
    href: '/departments',
  },
  {
    title: 'Khoa thần kinh',
    iconUrl: 'images/home_1/department_icon_5.svg',
    href: '/departments',
  },
  {
    title: 'Khoa tâm lý',
    iconUrl: 'images/home_1/department_icon_6.svg',
    href: '/departments',
  },
];

export default function Home() {
  pageTitle('Trang chủ');
  return (
    <>
      <Hero
        title="Trợ thủ đắc lực của Y tế và Sức khoẻ"
        subTitle="Chúng tôi cam kết cung cấp cho bạn các dịch vụ y tế và chăm sóc sức khỏe tốt nhất để giúp bạn vừa an tâm vừa khỏe mạnh."
        bgUrl="/images/home_1/hero_bg.jpeg"
        imgUrl="/images/home_1/hero_img.png"
        videoBtnText="Cách Yuumi vận hành"
        videoUrl="https://www.youtube.com/embed/57X97h9wjRQ"
        infoList={[
          {
            title: 'Hotline',
            subTitle: '123-456-7890',
            iconUrl: '/images/contact/icon_1.svg',
          },
          {
            title: 'Khẩn Cấp',
            subTitle: '08-115-115',
            iconUrl: '/images/icons/ambulance.svg',
          },
          {
            title: 'Địa Điểm',
            subTitle: 'Hồ Chí Minh, Việt Nam',
            iconUrl: '/images/icons/pin.svg',
          },
        ]}
        btnText="Đặt Lịch Ngay"
        btnUrl="/appointments"
      />
      {/* Start Feature Section */}
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={185}
        bottomLg={140}
        bottomXl={100}
      >
        <FeaturesSection sectionTitle="5 GIÁ TRỊ CỐT LÕI" data={featureListData} />
      </Section>
      {/* End Feature Section */}
      {/* Start About Section */}
      <Section>
        <AboutSection
          imgUrl="/images/home_1/about.png"
          spiningImgUrl="/images/home_1/about_mini.svg"
          title="Về Chúng Tôi"
          subTitle="YUUMI - Trợ Lý Sức Khoẻ Thông Minh"
          featureList={[
            {
              featureListTitle:
                'Yuumi là sự kết hợp giữa AI và đội ngũ chuyên gia y tế giàu kinh nghiệm',
              featureListSubTitle:
                'Dành riêng để cung cấp các dịch vụ chăm sóc sức khỏe chất lượng hàng đầu. Chúng tôi tin vào cách tiếp cận toàn diện trong chăm sóc sức khỏe, tập trung vào việc điều trị toàn diện con người, không chỉ bệnh tật hoặc triệu chứng.',
            },
          ]}
        />
      </Section>
      {/* End About Section */}
      {/* Start Departments Section */}
      <Section topMd={185} topLg={150} topXl={110}>
        <DepartmentSection
          sectionTitle="DỊCH VỤ Y TẾ"
          bgUrl="images/home_1/department_bg.svg"
          data={departmentData}
        />
      </Section>

      {/* End Departments Section */}
      {/* Start Award Section */}

      {/* End Award Section */}
      {/* Start Testimonial */}
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <TestimonialSection
          sectionTitle="MỘT VÀI ĐÁNH GIÁ"
          sectionTitleDown="VỀ YUUMI"
        />
      </Section>
      {/* End Testimonial */}
      {/* Start Banner Section */}
      <Section>
        <Banner
          bgUrl="images/home_1/cta_bg.svg"
          imgUrl="images/home_1/cta_img.png"
          title="VÌ MỘT VIỆT NAM AN TÂM, KHOẺ MẠNH!"
          subTitle="Hãy đặt lịch hẹn với một trong những chuyên gia y tế giàu kinh nghiệm của chúng tôi ngay hôm nay!"
        />
      </Section>
      {/* End Banner Section */}
      {/* Start Blog Section */}
      <Section topMd={190} topLg={145} topXl={105}>
        <BlogSection
          sectionTitle="CẬP NHẬT MỚI NHẤT"
          sectionTitleUp="CÁC BÀI ĐĂNG"
          data={blogData}
        />
      </Section>
      {/* End Blog Section */}
      {/* Start Appointment Section */}
      <Section topMd={190} topLg={145} topXl={105} id="appointment">
        <AppointmentSection
          sectionTitle="Lịch Khám"
          sectionTitleUp="ĐẶT NGAY"
          imgUrl="/images/home_1/appointment.jpeg"
        />
      </Section>
      {/* End Appointment Section */}
      {/* Start FAQ Section */}
      <Section topMd={190} topLg={145} topXl={105}>
        <FaqSection
          data={faqData}
          sectionTitle="Thường Gặp"
          sectionTitleUp="Những Câu Hỏi"
        />
      </Section>
      {/* End FAQ Section */}
      {/* Start Brand Section */}
      <Section
        topMd={200}
        topLg={150}
        topXl={110}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <BrandsSection data={brandData} />
      </Section>
      {/* End Brand Section */}
    </>
  );
}
