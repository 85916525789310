import React from 'react';
import { Link } from 'react-router-dom';
import Spacing from '../Spacing';
import { Icon } from '@iconify/react';

export default function CommentsWidget({ title }) {
  return (
    <div id="comments" className="comments-area">
      <h2 className="comments-title cs-semi_bold cs_fs_24 cs_semibold mb-0">
        {title}
      </h2>
      <Spacing md="45" />
      <ol className="comment-list">
        <li className="comment">
          <div className="comment-body">
            <div className="comment-author vcard">
              <img
                className="avatar"
                src="/images/blog/avatar_1.png"
                alt="Author"
              />
              <Link to="/" className="url">
              Trung Tín
              </Link>
            </div>
            <div className="comment-meta">
              <Icon icon="fa6-solid:clock" />
              <Link to="/">Tháng 5, 2024 </Link>
            </div>
            <p>
            Lúc đầu, tôi cũng lo lắm. Kiểu hay bị đau đầu do suy nghĩ này nọ mà công việc thì cứ đăng đăng đê đê không thể quan tâm đến sức khỏe bản thân được.. Sau này, mới biết mình bị mắc rối loạn lo âu nhưng nhờ chữa trị tại Yuumi hồi tháng 4/2024 tôi đã đỡ hơn rât nhiều.
            </p>
            <div className="reply">
              <Link className="comment-reply-link" to="/">
                Trả lời
              </Link>
            </div>
          </div>
          <ol className="children">
            <li className="comment">
              <div className="comment-body">
                <div className="comment-author vcard">
                  <img
                    className="avatar"
                    src="/images/blog/avatar_2.png"
                    alt="Author"
                  />
                  <Link to="/" className="url">
                  Kim Vân
                  </Link>
                </div>
                <div className="comment-meta">
                  <Icon icon="fa6-solid:clock" />
                  <Link to="/">Tháng 5, 2024 </Link>
                </div>
                <p>
                Tôi xác nhận rằng mình bị rối loạn lo âu từ rất sớm nhờ vào gói tầm soát sức khỏe Yuumi. Chính vì vậy, mà tôi đã có thể duy trì lối sống lành mạnh, có chiến lược cụ thể trong quản lý sức khỏe bản thân.
                </p>
                <div className="reply">
                  <Link className="comment-reply-link" to="/">
                    Trả lời
                  </Link>
                </div>
              </div>
            </li>
            {/* #comment-## */}
          </ol>
          {/* .children */}
        </li>
        {/* #comment-## */}
        <li className="comment">
          <div className="comment-body">
            <div className="comment-author vcard">
              <img
                className="avatar"
                src="/images/blog/avatar_3.png"
                alt="Author"
              />
              <Link to="/" className="url">
              Ánh Vân
              </Link>
            </div>
            <div className="comment-meta">
              <Icon icon="fa6-solid:clock" />
              <Link to="/">Tháng 5, 2024 </Link>
            </div>
            <p>
            Tôi là người kinh doanh nên một ngày tôi có cả đống công việc cần phải xong và vô tình tôi bỏ rơi sức khỏe của mình. Cho đến một ngày, bạn tôi giới thiệu gói khám đang khuyến mãi tại Yuumi, tôi cũng đi thử và biết rằng mình mắc chứng rối loạn lo âu nên mới thường hay đau đầu và tiêu cực.
            </p>
            <div className="reply">
              <Link className="comment-reply-link" to="/">
                Trả lời
              </Link>
            </div>
          </div>
        </li>
        {/* #comment-## */}
      </ol>
      {/* .comment-list */}
    </div>
  );
}
