import React, { useState } from 'react';

export default function ContactForm() {
  const [notification, setNotification] = useState('');
  const handleContact = () => {
    setNotification('Bệnh viện đã nhận thông tin của bạn và sẽ sớm phản hồi');
  };
  const notificationStyle = {
    color: 'green',
    backgroundColor: '#DFF2BF',
    border: '10px',
    borderRadius: '5px',
    padding: '10px',
    marginTop: '10px',
  };
  return (
    <div className="cs_contact_form cs_style_1 cs_white_bg cs_radius_30">
      <div className="row">
        <div className="col-lg-6">
          <label className="cs_input_label cs_heading_color">Họ và Tên</label>
          <input
            type="text"
            className="cs_form_field"
            placeholder="David John"
          />
          <div className="cs_height_42 cs_height_xl_25" />
        </div>
        <div className="col-lg-6">
          <label className="cs_input_label cs_heading_color">Email</label>
          <input
            type="email"
            className="cs_form_field"
            placeholder="example@gmail.com"
          />
          <div className="cs_height_42 cs_height_xl_25" />
        </div>
        <div className="col-lg-12">
          <label className="cs_input_label cs_heading_color">Chủ Đề</label>
          <input
            type="text"
            className="cs_form_field"
            placeholder="Chủ đề"
          />
          <div className="cs_height_42 cs_height_xl_25" />
        </div>
        <div className="col-lg-12">
          <label className="cs_input_label cs_heading_color">Nội Dung</label>
          <textarea
            cols={30}
            rows={10}
            className="cs_form_field"
            placeholder="Điền nội dung..."
            defaultValue={''}
          />
          <div className="cs_height_42 cs_height_xl_25" />
        </div>
        <div className="col-lg-12">
          <div className="cs_height_18" />
          <button className="cs_btn cs_style_1" onClick={handleContact}>
            <span>Gửi</span>
            <i>
              <img src="/images/icons/arrow_white.svg" alt="Icon" />
              <img src="/images/icons/arrow_white.svg" alt="Icon" />
            </i>
          </button>
        </div>
        {notification && <div className="notification" style={notificationStyle}>{notification}</div>}
      </div>
    </div>
  );
}
