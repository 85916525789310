import React from 'react';
import SideMenuWidget from '../Widget/SideMenuWidget';
import RecentPostWidget from '../Widget/RecentPostWidget';
import NewsletterStyle5 from '../Widget/NewsletterStyle5';
const categoryData = [
  {
    title: 'Lời khuyên và mẹo về sức khỏe',
    url: '/',
  },
  {
    title: 'Xu hướng và phân tích',
    url: '/',
  },
  {
    title: 'Quản lý thời gian',
    url: '/',
  },
];
const recentPostData = [
  {
    title: `Hướng dẫn dành cho phụ huynh về tiêm chủng cho trẻ em: Những điều bạn cần biết`,
    author: 'Yến Vy',
    date: 'Tháng 5, 2024',
    href: '/blog/blog-details',
  },
  {
    title: `Ngăn ngừa bệnh tim: Lời khuyên cho lối sống lành mạnh cho tim`,
    author: 'Yến Vy',
    date: 'Tháng 5, 2024',
    href: '/blog/blog-details',
  },
  {
    title: `Kiểm soát cơn đau mãn tính: Các lựa chọn và chiến lược điều trị`,
    author: 'Yến Vy',
    date: 'Tháng 5, 2024',
    href: '/blog/blog-details',
  },
  {
    title: `Vai trò của Vật lý trị liệu trong việc phục hồi và phòng ngừa chấn thương`,
    author: 'Yến Vy',
    date: 'Tháng 5, 2024',
    href: '/blog/blog-details',
  },
  {
    title: `Dị ứng và hen suyễn: Nguyên nhân, triệu chứng và lựa chọn điều trị    `,
    author: 'Yến Vy',
    date: 'Tháng 5, 2024',
    href: '/blog/blog-details',
  },
];

export default function Sidebar() {
  return (
    <div className="cs_sidebar">
      <div className="cs_sidebar_item widget_categories" style={{fontFamily:"Arial, Helvetica, sans-serif"}}>
        <SideMenuWidget title="Danh mục phổ biến" data={categoryData} />
      </div>
      <div className="cs_sidebar_item">
        <RecentPostWidget title="Các bài viết phổ biến" data={recentPostData} />
      </div>
      <div className="cs_sidebar_item widget_categories">
        <NewsletterStyle5 title="Đăng nhập Newsletter" />
      </div>
    </div>
  );
}
