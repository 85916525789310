import React from 'react';
import { Link } from 'react-router-dom';
import Section from '../Section';
import Breadcrumb from '../Breadcrumb';
import BannerSectionStyle9 from '../Section/BannerSection/BannerSectionStyle9';
import { Icon } from '@iconify/react';
import Spacing from '../Spacing';
import Post from '../Post';
import Sidebar from '../Sidebar';
import CommentsWidget from '../Widget/CommentsWidget';
import ReplyWidget from '../Widget/ReplyWidget';
import { pageTitle } from '../../helpers/PageTitle';
const tags = [
  { tag: 'Cấp cứu', href: '/blog/blog-details' },
  { tag: 'Khoa nhi', href: '/blog/blog-details' },
  { tag: 'Khoa tim mạch', href: '/blog/blog-details' },
  { tag: 'Khoa thần kinh', href: '/blog/blog-details' },
  { tag: 'Phụ khoa', href: '/blog/blog-details' },
];
const relatedBlog = [
  {
    title:
      'Tầm quan trọng của sức khỏe tâm thần: Hiểu và quản lý rối loạn lo âu',
    thumbUrl: '/images/blog/post_1.jpeg',
    date: 'Tháng 5',
    btnText: 'Đọc thêm',
    href: '/blog/blog-details',
  },
  {
    title: `Hướng dẫn dành cho phụ huynh về tiêm chủng cho trẻ em: Những điều bạn cần biết`,
    thumbUrl: '/images/blog/post_2.jpeg',
    date: 'Tháng 5',
    btnText: 'Đọc thêm',
    href: '/blog/blog-details',
  },
  {
    title: 'Ngăn ngừa bệnh tim: Lời khuyên cho lối sống lành mạnh cho tim',
    thumbUrl: '/images/blog/post_3.jpeg',
    date: 'Tháng 5',
    btnText: 'Learn More',
    href: '/blog/blog-details',
  },
];

export default function BlogDetails() {
  pageTitle('Chi tiết');
  return (
    <>
      <Section topMd={170} bottomMd={54} bottomLg={54}>
        <Breadcrumb title="Tầm quan trọng của sức khỏe tâm thần: Hiểu và quản lý rối loạn lo âu" />
      </Section>
      <div className="container">
        <div className="cs_blog_details_info">
          <div className="cs_blog_details_info_left">
            <div className="cs_blog_details_tags">
              {tags.map((item, index) => (
                <Link key={index} to={item.href}>
                  {item.tag}
                </Link>
              ))}
            </div>
            <div className="cs_blog_details_date">
              Tháng 5, 2024 | Kim Vân
            </div>
          </div>
          <div className="cs_social_links_wrap">
            <h2>Chia sẻ:</h2>
            <div className="cs_social_links">
              <Link to="/">
                <Icon icon="fa-brands:facebook-f" />
              </Link>
              <Link to="/">
                <Icon icon="fa-brands:linkedin-in" />
              </Link>
              <Link to="/">
                <Icon icon="fa-brands:twitter" />
              </Link>
            </div>
          </div>
        </div>
        <Spacing md="55" />
        <img
          src="/images/blog/blog_details_1.jpeg"
          alt="Blog Details"
          className="w-100 cs_radius_20"
        />
        <Spacing md="90" lg="50" />
        <div className="row">
          <div className="col-lg-8">
            <div className="cs_blog_details">
              <h2>Rối loạn lo âu là gì</h2>
              <p>
                Rối loạn lo âu là một loại rối loạn sức khỏe tâm thần được đặc trưng bởi cảm giác lo lắng, hồi hộp hoặc sợ hãi đủ mạnh để cản trở các hoạt động hàng ngày của một người. Những cảm giác này có thể xuất hiện do một số tình huống nhất định, thường liên quan đến căng thẳng hoặc chấn thương, nhưng cũng có thể xảy ra mà không có lý do rõ ràng. <br />
                <br />
                Có một số loại rối loạn lo âu, bao gồm:
              </p>
              <ol>
                <li>
                  <b>Rối loạn lo âu tổng quát (GAD):</b>
                  <br />
                  Điều này được đặc trưng bởi sự lo lắng mãn tính, lo lắng thái quá và căng thẳng, ngay cả khi có rất ít hoặc không có gì gây ra nó.
                </li>
                <li>
                  <b>Rối loạn hoảng sợ:</b> <br />
                  Điều này liên quan đến các giai đoạn lặp đi lặp lại của cảm giác lo lắng và sợ hãi hoặc khủng bố dữ dội đột ngột đạt đến đỉnh điểm trong vòng vài phút (cơn hoảng loạn).
                </li>
                <li>
                  <b>Rối loạn liên quan đến ám ảnh:</b>
                  <br />
                  Những điều này liên quan đến nỗi sợ hãi hoặc ác cảm mãnh liệt đối với các tình huống hoặc đối tượng cụ thể vượt quá ranh giới bình thường và có thể dẫn đến hành vi né tránh.
                </li>
                <li>
                  <b>Rối loạn lo âu xã hội (ám ảnh xã hội):</b>
                  <br />
                  Đây là mức độ sợ hãi, bối rối hoặc sỉ nhục đáng kể trong các tình huống dựa trên hiệu suất xã hội, dẫn đến hành vi né tránh.
                </li>
                <li>
                  <b>Rối loạn ám ảnh cưỡng chế (OCD):</b> <br />
                  Điều này được đặc trưng bởi những suy nghĩ không mong muốn (ám ảnh) và/hoặc hành vi lặp đi lặp lại (cưỡng chế).
                </li>
                <li>
                  <b>Rối loạn căng thẳng sau chấn thương (PTSD):</b> <br />

                  Điều này có thể phát triển sau khi tiếp xúc với một sự kiện hoặc thử thách đáng sợ trong đó xảy ra tổn hại nghiêm trọng về thể chất hoặc bị đe dọa.

                </li>
              </ol>
              <p>
              Các triệu chứng của rối loạn lo âu có thể bao gồm cảm giác hoảng sợ, sợ hãi và khó chịu, khó ngủ, tay hoặc chân lạnh hoặc ra mồ hôi, khó thở, tim đập nhanh, không thể đứng yên và bình tĩnh, khô miệng, tê hoặc ngứa ran ở tay hoặc chân, buồn nôn, căng cơ và chóng mặt. <br /> <br />
              Việc điều trị những rối loạn này vẫn tồn tại và thường bao gồm sự kết hợp giữa liệu pháp tâm lý, liệu pháp hành vi và thuốc. Liệu pháp nhận thức hành vi là một hình thức trị liệu đặc biệt hiệu quả giúp mọi người học cách nhận biết và thay đổi các kiểu suy nghĩ dẫn đến cảm giác rắc rối. Ngoài ra, thay đổi lối sống, chẳng hạn như điều chỉnh chế độ ăn uống, tăng cường hoạt động thể chất và giảm lượng caffeine, cũng có thể giúp kiểm soát các triệu chứng.<br />
                <br />
                Vui lòng tham khảo ý kiến của nhà cung cấp dịch vụ chăm sóc sức khỏe để được chẩn đoán và điều trị chính xác nếu bạn hoặc ai đó bạn biết đang gặp phải các triệu chứng của rối loạn lo âu. Đây không phải là danh sách đầy đủ các chứng rối loạn lo âu hoặc các triệu chứng của chúng, vì vậy, bạn nên luôn tìm kiếm sự trợ giúp từ chuyên gia khi có vấn đề về sức khỏe tâm thần.
              </p>
              <blockquote
                style={{
                  backgroundImage: 'url("/images/blog/blog_details_2.jpeg")',
                }}
              >
                <p>
                  “Việc điều trị những chứng rối loạn này vẫn tồn tại và thường bao gồm sự kết hợp giữa liệu pháp tâm lý, liệu pháp hành vi và thuốc uống.”
                </p>
              </blockquote>
              <h2>
              Cách quản lý rối loạn lo âu: Kỹ thuật và chiến lược
              </h2>
              <p>
              Rối loạn lo âu có thể tràn ngập và cản trở cuộc sống hàng ngày, nhưng có nhiều cách để kiểm soát những cảm xúc này. Từ các chiến lược tự chăm sóc đến sự trợ giúp của chuyên gia, dưới đây là các phương pháp giúp đối phó hiệu quả với chứng rối loạn lo âu.
              </p>
              <ul>
                <li>
                  <h3>1. Điều trị chuyên nghiệp</h3>
                  Nếu bạn đang phải đối mặt với chứng rối loạn lo âu, việc tìm kiếm sự trợ giúp từ chuyên gia là rất quan trọng. Các chuyên gia sức khỏe tâm thần có thể đưa ra chẩn đoán và đề xuất các lựa chọn điều trị thích hợp. Chúng thường bao gồm:
                </li>
                <li>
                  <h3>2. Kỹ thuật tự chăm sóc</h3>
                  Ngoài việc điều trị chuyên nghiệp, có một số chiến lược tự chăm sóc mà bạn có thể áp dụng để giúp kiểm soát lo lắng: <br />
                  <br />
                  <b>Kỹ thuật thiền và thư giãn chánh niệm:</b> Các bài tập thở sâu, thư giãn cơ dần dần và thiền chánh niệm có thể làm giảm các triệu chứng lo âu bằng cách thúc đẩy thư giãn và giảm cảm giác sợ hãi và lo lắng.{' '}
                  <br />
                  <br />
                  <b>Lối sống lành mạnh:</b> Tập thể dục thường xuyên, chế độ ăn uống lành mạnh, ngủ đủ giấc và giảm caffeine và rượu cũng có thể giúp kiểm soát các triệu chứng lo âu. <br />
                  <br />
                  <b>Duy trì quan điểm tích cực:</b> Mặc dù việc này có vẻ khó khăn nhưng việc tập trung vào những khía cạnh tích cực trong cuộc sống của bạn sẽ rất hữu ích. Các bài tập về lòng biết ơn hoặc viết nhật ký để ghi lại những trải nghiệm tích cực có thể giúp nuôi dưỡng quan điểm lạc quan. <br />
                  <br />
                  <b>Tránh né tránh:</b> Những người mắc chứng lo âu thường tránh né những tình huống hoặc đồ vật khiến họ lo lắng. Tuy nhiên, tránh những tác nhân này có thể làm tăng thêm nỗi sợ hãi. Việc tiếp xúc an toàn với những tác nhân này dưới sự hướng dẫn của nhà trị liệu có thể giúp kiểm soát và cuối cùng là giảm bớt lo lắng.
                </li>
                <li>
                  <h3>3. Sử dụng công nghệ</h3>
                  Có rất nhiều nền tảng sức khỏe kỹ thuật số, chẳng hạn như ứng dụng điện thoại thông minh và dịch vụ tư vấn trực tuyến, có thể giúp kiểm soát chứng rối loạn lo âu. Những nền tảng này có thể cung cấp liệu pháp nhận thức-hành vi, đào tạo chánh niệm và các tài nguyên hữu ích khác.
                </li>
              </ul>
              <h2>Phần kết luận</h2>
              <p>
              Rối loạn lo âu có thể khiến bạn nản lòng, nhưng với sự kết hợp phù hợp giữa trợ giúp chuyên môn và chiến lược đối phó cá nhân, chúng có thể được kiểm soát một cách hiệu quả. Điều quan trọng cần nhớ là luôn có sự trợ giúp và bạn không đơn độc trong cuộc đấu tranh của mình.{' '}
                <br />
                <br />
                Hãy nhớ rằng, điều quan trọng là phải liên hệ với chuyên gia sức khỏe tâm thần nếu bạn đang gặp phải các triệu chứng của chứng rối loạn lo âu. Điều gì hiệu quả nhất sẽ tùy thuộc vào từng cá nhân và loại rối loạn lo âu, và điều cần thiết là phải có sự hướng dẫn của chuyên gia để quản lý hiệu quả.
              </p>
            </div>
            {/*<Spacing md="85" />
            <AuthorWidget
              imgUrl="/images/blog/author.png"
              name="Author Bio"
              description="John Smith is a freelance writer and content strategist with a passion for helping businesses tell their stories. With over 10 years of experience in the industry, John has worked with a wide range of clients, from startups to Fortune 500 companies. He holds a Bachelor's degree in English from the University of California, Los Angeles (UCLA), and is an avid reader and traveler in his free time. Follow him on Twitter @johnsmithwriter for the latest updates on his work."
              />*/}
            <Spacing md="110" />
            <CommentsWidget title="Bình luận" />
            <Spacing md="92" />
            <ReplyWidget title="Để lại lời nhắn" />
          </div>
          <div className="col-lg-4">
            <Sidebar />
          </div>
        </div>
        <Spacing md="135" lg="100" />
        <h2 className="mb-0 cs_fs_40 cs_medium">Các bài viết liên quan</h2>
        <Spacing md="57" />
        <div className="row cs_gap_y_40">
          {relatedBlog?.map((item, index) => (
            <div className="col-xl-4 col-md-6" key={index}>
              <Post {...item} />
            </div>
          ))}
        </div>
      </div>
      <Spacing md="200" xl="150" lg="110" />
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle9
          title="VÌ MỘT VIỆT NAM <br/> AN TOÀN, KHOẺ MẠNH!"
          subTitle="Đặt lịch với một trong những <br />chuyên gia y tế của chúng tôi hôm nay"
          imgUrl="/images/doctors/banner_img_3.png"
        />
      </Section>
    </>
  );
}
