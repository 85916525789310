import React from 'react';
import BannerSectionStyle3 from '../Section/BannerSection/BannerSectionStyle3';
import { pageTitle } from '../../helpers/PageTitle';
import Section from '../Section';
import PricingSection from '../Section/PricingSection';
import FaqSectionStyle4 from '../Section/FaqSection/FaqSectionStyle4';
import BannerSectionStyle10 from '../Section/BannerSection/BannerSectionStyle10';
const pricingData = [
  {
    title: 'Gói khám tầm soát ung thư toàn diện nam',
    subTitle:
      'Gói khám này được thiết kế để phát hiện sớm các dấu hiệu ung thư phổ biến ở nam giới, bao gồm ung thư phổi, gan, dạ dày, trực tràng, và tuyến tiền liệt. Chúng tôi sử dụng các phương pháp chẩn đoán hiện đại nhất để đảm bảo phát hiện kịp thời và chính xác.',
    price: '4.000.000 VND',
    period: '/lượt',
    featureList: [
      'Theo dõi 24/24 sức khỏe',
      'Lộ trình chữa trị riêng biệt',
      'Khám và tư vấn bác sĩ',
      'Chẩn đoán hình ảnh',
      'Làm các xét nghiệm khác nhau'
    ],
    btnText: 'Chọn gói',
    btnUrl: '/contact',
    popular: false,
  },
  {
    title: 'Tổng quát',
    subTitle:
      'Gói khám tổng quát cung cấp một bức tranh toàn diện về tình trạng sức khỏe hiện tại của bạn. Gói này bao gồm các xét nghiệm máu, kiểm tra chức năng gan, thận, tim mạch, và nhiều hạng mục khác để đánh giá sức khỏe tổng thể và phát hiện sớm các vấn đề tiềm ẩn.',
    price: '2.500.000 VND',
    period: '/lượt',
    featureList: [
      'Khám và tư vấn bác sĩ',
      'Chẩn đoán hình ảnh',
      'Làm các xét nghiệm khác nhau',
    ],
    btnText: 'Chọn gói',
    btnUrl: '/contact',
    popular: true,
  },
  {
    title: 'Gói khám tầm soát ung thư toàn diện nữ',
    subTitle:
      'Gói khám này giúp phát hiện sớm các dấu hiệu ung thư thường gặp ở nữ giới, bao gồm ung thư vú, cổ tử cung, buồng trứng, phổi, gan, và dạ dày. Chúng tôi cam kết mang lại sự an tâm và sức khỏe toàn diện cho phụ nữ qua các xét nghiệm và chẩn đoán chuyên sâu.',
    price: '4.000.000 VND',
    period: '/lượt',
    featureList: [
      'Theo dõi 24/24 sức khỏe',
      'Lộ trình chữa trị riêng biệt',
      'Khám và tư vấn bác sĩ',
      'Chẩn đoán hình ảnh',
      'Làm các xét nghiệm khác nhau'
    ],
    btnText: 'Chọn gói',
    btnUrl: '/contact',
    popular: false,
  },
  {
    title: 'Tầm soát ung thư gan',
    subTitle:
      'Gói khám này chuyên biệt để phát hiện sớm ung thư gan, bao gồm các xét nghiệm chức năng gan, siêu âm gan, và các phương pháp chẩn đoán tiên tiến khác. Mục tiêu của chúng tôi là phát hiện và điều trị sớm để tối ưu hóa kết quả cho bệnh nhân.',
    price: '3.000.000 VND',
    period: '/lượt',
    featureList: [
      'Khám và tư vấn bác sĩ',
      'Chẩn đoán hình ảnh',
      'Làm các xét nghiệm khác nhau',
      'Lộ trình chữa trị riêng biệt',
    ],
    btnText: 'Chọn gói',
    btnUrl: '/contact',
    popular: false,
  },
  {
    title: `Tầm soát ung thư dạ dày`,
    subTitle: `Gói tầm soát này giúp phát hiện sớm ung thư dạ dày thông qua các xét nghiệm như nội soi dạ dày, xét nghiệm máu và các phương pháp chẩn đoán hiện đại khác. Chúng tôi giúp bạn bảo vệ sức khỏe dạ dày bằng cách phát hiện sớm và điều trị kịp thời.`,
    price: '2.500.000 VND',
    period: '/lượt',
    featureList: [
      `Khám và tư vấn bác sĩ`,
      'Chẩn đoán hình ảnh',
      'Làm các xét nghiệm khác nhau',
      'Lộ trình chữa trị riêng biệt'
    ],
    btnText: 'Chọn gói',
    btnUrl: '/contact',
    popular: false,
  },
  {
    title: 'Tầm soát ung thư trực tràng',
    subTitle:
      'Gói khám này bao gồm các xét nghiệm và chẩn đoán chuyên sâu để phát hiện sớm ung thư trực tràng, chẳng hạn như nội soi đại tràng và xét nghiệm máu. Chúng tôi tập trung vào việc phát hiện sớm để cải thiện hiệu quả điều trị.',
    price: '4.500.000 VND',
    period: '/lượt',
    featureList: [
      'Khám và tư vấn bác sĩ',
      'Chẩn đoán hình ảnh',
      'Làm các xét nghiệm khác nhau',
      'Lộ trình chữa trị riêng biệt',
    ],
    btnText: 'Chọn gói',
    btnUrl: '/contact',
    popular: false,
  },
  {
    title: 'Tầm soát ung thư vú',
    subTitle:
      'Gói tầm soát ung thư vú được thiết kế để phát hiện sớm các dấu hiệu ung thư vú qua các phương pháp chụp nhũ ảnh, siêu âm vú và xét nghiệm máu. Chúng tôi cung cấp dịch vụ chẩn đoán nhanh chóng và chính xác để bảo vệ sức khỏe phụ nữ.',
    price: '1.500.000 VND',
    period: '/lượt',
    featureList: [
      'Khám và tư vấn bác sĩ',
      'Chẩn đoán hình ảnh',
      'Làm các xét nghiệm khác nhau',
      'Lộ trình chữa trị riêng biệt',
    ],
    btnText: 'Chọn gói',
    btnUrl: '/contact',
    popular: false,
  },
];
const faqData = [
  {
    title: 'Yuumi cung cấp những dịch vụ gì ?',
    content:
      'Yuumi cung cấp nhiều loại gói khám chuyên sâu đặc biệt phù hợp với nhu cầu cá nhân, cùng với vô vàn ưu đãi giúp bạn vừa an tâm vừa khỏe mạnh.',
  },
  {
    title: 'Làm thế nào để đặt lịch với Yuumi ?',
    content:
      'Bạn có thể đặt lịch trực tiếp trên trang website hoặc thông qua sự hướng dẫn của trợ lý Yuumi.',
  },
  {
    title: 'Bạn có chấp nhận bảo hiểm không ?',
    content:
    <div>
      Có. Khi đặt lịch khám và chọn dịch vụ y tế qua Yuumi, bạn sẽ có tùy chọn nhập thông tin bảo hiểm của mình. Hệ thống của chúng tôi sẽ xác minh thông tin bảo hiểm và tính toán phần chi phí được bảo hiểm chi trả.
      <br/>
      <br/>
      Người dùng có thể thấy rõ phần chi phí được bảo hiểm chi trả và phần còn lại mà họ phải thanh toán trực tiếp qua ứng dụng Yuumi, giúp quá trình thanh toán trở nên minh bạch và dễ dàng hơn.
    </div>
  },
  {
    title: 'Tôi nên chuẩn bị những gì khi đến khám ?',
    content:
    <div>
      1. Kiểm tra lại lịch hẹn của bạn trên ứng dụng Yuumi: Để đảm bảo không có thay đổi hoặc nhầm lẫn về thời gian và địa điểm. Yuumi cũng cho phép bạn thay đổi hoặc hủy lịch hẹn một cách dễ dàng nếu cần thiết.
      <br/>
      <br/>
      2. Mang theo thẻ bảo hiểm và giấy tờ tùy thân: Chứng minh nhân dân, căn cước công dân hoặc hộ chiếu để xác nhận danh tính. Điều này giúp bác sĩ và cơ sở y tế kiểm tra và cập nhật thông tin của bạn một cách chính xác trên hệ thống Yuumi.
      <br/>
      <br/>
      3. Hồ sơ bệnh án điện tử: Nếu bạn đã từng khám bệnh trước đó, hãy đảm bảo rằng các hồ sơ bệnh án, kết quả xét nghiệm, đơn thuốc cũ đều đã được tải lên và cập nhật trên hệ thống Yuumi. Điều này giúp bác sĩ nhanh chóng nắm bắt được tình trạng sức khỏe của bạn mà không cần bạn mang theo nhiều giấy tờ.
      <br/>
      <br/>
      4. Chuẩn bị cho các xét nghiệm: Nếu bạn được yêu cầu làm các xét nghiệm đặc biệt, Yuumi sẽ gửi thông báo và hướng dẫn chuẩn bị chi tiết. Làm theo các hướng dẫn này để đảm bảo kết quả xét nghiệm chính xác và buổi khám diễn ra suôn sẻ.
      </div>
  },
  {
    title: 'Làm cách nào để yêu cầu mua thêm thuốc theo toa ?',
    content:
      'Yuumi có hỗ trợ giao thuốc tận nhà theo toa theo yêu cầu của khách hàng trong 2 giờ. Các bác sĩ đều trả kết quả khám bệnh và đơn thuốc qua Yuumi nên bạn có thể hoàn toàn đặt giao hàng đơn thuốc của bạn đến tận nhà qua Yuumi.',
  },
];
export default function PricingPlan() {
  pageTitle('Pricing Plan');
  return (
    <>
      <BannerSectionStyle3
        bgUrl="/images/pricing_plan/banner_bg.svg"
        imgUrl="/images/pricing_plan/banner_img.png"
        title="Tìm kế hoạch phù hợp cho bạn"
        subTitle="Khám phá các gói khám đa dạng của chúng tôi và <br/> bắt đầu hành trình hướng tới sức khỏe tốt hơn"
        btnText="Bắt đầu ngay" 
        btnUrl="/contact"
      />
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <PricingSection
          sectionTitle="Chọn một trong những <br /> Gói chữa bệnh của Yuumi"
          data={pricingData}
        />
      </Section>
      <Section
        topMd={185}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
        className="cs_gray_bg_1"
      >
        <FaqSectionStyle4
          sectionTitle="Những câu hỏi <br />thường xuyên"
          data={faqData}
          faqImgUrl="/images/pricing_plan/faq_img.jpeg"
          spiningImgUrl="/images/home_1/about_mini.svg"
        />
      </Section>
      <Section
        topMd={200}
        topLg={150}
        topXl={110}
        className="cs_footer_margin_0"
      >
        <BannerSectionStyle10
          imgUrl="/images/pricing_plan/banner_img_2.png"
          title="Chọn gói khám để đầu tư vào <br/> Sức khoẻ của bạn ngay hôm nay!"
        />
      </Section>
    </>
  );
}
