import React from 'react';
import BannerSectionStyle9 from '../Section/BannerSection/BannerSectionStyle9';
import Section from '../Section';
import BlogSectionStyle2 from '../Section/BlogSection/BlogSectionStyle2';
import Breadcrumb from '../Breadcrumb';
import { pageTitle } from '../../helpers/PageTitle';
const blogData = [
  {
    title:
      'Tầm quan trọng của sức khỏe tâm thần: Hiểu và quản lý rối loạn lo âu',
    thumbUrl: 'images/blog/post_1.jpeg',
    date: 'Tháng 5, 2024',
    btnText: 'Learn More',
    href: '/blog/blog-details',
    socialShare: true,
  },
  {
    title: `Hướng dẫn dành cho phụ huynh về tiêm chủng cho trẻ em: Những điều bạn cần biết`,
    thumbUrl: 'images/blog/post_2.jpeg',
    date: 'Tháng 5, 2024',
    btnText: 'Đọc thêm',
    href: '/blog/blog-details',
    socialShare: true,
  },
  {
    title: 'Ngăn ngừa bệnh tim: Lời khuyên cho lối sống lành mạnh cho tim',
    thumbUrl: 'images/blog/post_3.jpeg',
    date: 'Tháng 5, 2024',
    btnText: 'Đọc thêm',
    href: '/blog/blog-details',
    socialShare: true,
  },
  {
    title: 'Kiểm soát cơn đau mãn tính: Các lựa chọn và chiến lược điều trị',
    thumbUrl: 'images/blog/post_4.jpeg',
    date: 'Tháng 5, 2024',
    btnText: 'Đọc thêm',
    href: '/blog/blog-details',
    socialShare: true,
  },
  {
    title: 'Hiểu và quản lý bệnh tiểu đường: Lời khuyên để sống lành mạnh',
    thumbUrl: 'images/blog/post_5.jpeg',
    date: 'Tháng 5, 2024',
    btnText: 'Đọc thêm',
    href: '/blog/blog-details',
    socialShare: true,
  },
  {
    title: 'Vai trò của Vật lý trị liệu trong việc phục hồi và phòng ngừa chấn thương',
    thumbUrl: 'images/blog/post_6.jpeg',
    date: 'Tháng 5, 2024',
    btnText: 'Đọc thêm',
    href: '/blog/blog-details',
    socialShare: true,
  },
  {
    title: 'Phá vỡ sự định kiến: Nâng cao nhận thức về các vấn đề sức khỏe tinh thần',
    thumbUrl: 'images/blog/post_7.jpeg',
    date: 'Tháng 5, 2024',
    btnText: 'Đọc thêm',
    href: '/blog/blog-details',
    socialShare: true,
  },
  {
    title: 'Dị ứng và hen suyễn: Nguyên nhân, triệu chứng và lựa chọn điều trị',
    thumbUrl: 'images/blog/post_8.jpeg',
    date: 'Tháng 5, 2024',
    btnText: 'Đọc thêm',
    href: '/blog/blog-details',
    socialShare: true,
  },
  {
    title: 'Chăm sóc làn da của bạn: Lời khuyên cho làn da khỏe mạnh, sáng mịn',
    thumbUrl: 'images/blog/post_9.jpeg',
    date: 'Tháng 5, 2024',
    btnText: 'Đọc thêm',
    href: '/blog/blog-details',
    socialShare: true,
  },
];

export default function Blog() {
  pageTitle('Blog');
  return (
    <>
      <Section topMd={170} bottomMd={96} bottomLg={70}>
        <Breadcrumb title="Sức khoẻ tinh thần và Lối sống lành mạnh" />
      </Section>
      <Section bottomMd={200} bottomLg={150} bottomXl={110}>
        <BlogSectionStyle2 data={blogData} />
      </Section>
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle9
          title="VÌ MỘT VIỆT NAM <br/> AN TOÀN,  KHOẺ MẠNH"
          subTitle="Đặt lịch với một trong những <br />chuyên gia y tế của chúng tôi ngay hôm nay!"
          imgUrl="/images/doctors/banner_img_3.png"
        />
      </Section>
    </>
  );
}
