import React, { useState } from 'react';

export default function ReplyWidget({ title }) {
  const [notification, setNotification] = useState('');
  const handleReply = () => {
    setNotification('Bệnh viện đã nhận được lời nhắn và sẽ sớm phản hồi');
  };
  const notificationStyle = {
    color: 'green',
    backgroundColor: '#DFF2BF',
    border: '10px',
    borderRadius: '5px',
    padding: '10px',
    marginTop: '10px',
  };
  return (
    <>
      <h3 className="cs_semibold cs_fs_24 mb-0">{title}</h3>
      <div className="cs_height_12" />
      <p className="cs_fs_18">
        Email của bạn sẽ được bảo mật. Các chỗ trống đánh dấu * là bắt buộc điền
      </p>
      <div className="cs_height_7" />
      <form action="#">
        <label className="cs_input_label cs_heading_color cs_fs_18 cs_medium" style={{ fontFamily: "Arial, Helvetica, sans-serif" }} >
          Lời nhắn*
        </label>
        <textarea
          cols={30}
          rows={8}
          className="cs_form_field_2"
          defaultValue={''}
        />
        <div className="cs_height_20" />
        <label className="cs_input_label cs_heading_color cs_fs_18 cs_medium" style={{ fontFamily: "Arial, Helvetica, sans-serif" }} >
          Họ và tên*
        </label>
        <input type="text" className="cs_form_field_2" />
        <div className="cs_height_20" />
        <label className="cs_input_label cs_heading_color cs_fs_18 cs_medium" style={{ fontFamily: "Arial, Helvetica, sans-serif" }} >
          Email cá nhân*
        </label>
        <input type="email" className="cs_form_field_2" />
        <div className="cs_height_30" />
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            defaultValue=""
            id="flexCheckDefault"
          />
          <label className="form-check-label" htmlFor="flexCheckDefault">
            Lưu tên và email của tôi trong trình duyệt này cho lần bình luận tiếp theo.
          </label>
        </div>
        <div className="cs_height_60" />
          <button className="cs_btn cs_style_1" onClick={handleReply}>
            <span>Gửi</span>
            <i>
              <img src="/images/icons/arrow_white.svg" alt="Icon" />
              <img src="/images/icons/arrow_white.svg" alt="Icon" />
            </i>
          </button>
        {notification && <div className="notification" style={notificationStyle}>{notification}</div>}
      </form>
    </>
  );
}
