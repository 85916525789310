import React from 'react';
import BannerSectionStyle5 from '../Section/BannerSection/BannerSectionStyle5';
import BannerSectionStyle4 from '../Section/BannerSection/BannerSectionStyle4';
import TeamSectionStyle2 from '../Section/TeamSection/TeamSectionStyle2';
import Section from '../Section';
import { pageTitle } from '../../helpers/PageTitle';
const teamData = [
  {
    imgUrl: '/images/doctors/doctor_1.png',
    department: 'Khoa cấp cứu',
    name: 'Bác sĩ Lisa Chen',
    designation: 'Bác sĩ Cấp cứu',
    description:
      'Bác sĩ Chen có hơn 10 năm kinh nghiệm trong lĩnh vực cấp cứu và được hội đồng chứng nhận về cấp cứu. Cô có chuyên môn đặc biệt trong việc chăm sóc quan trọng và quản lý chấn thương.',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
    category: 'Khoa cấp cứu',
    href: '/doctors',
  },
  {
    imgUrl: '/images/doctors/doctor_2.png',
    department: 'Khoa cấp cứu',
    name: 'Bác sĩ Mike John',
    designation: 'Bác sĩ Cấp cứu',
    description:
      'Bác sĩ Johnson là bác sĩ cấp cứu giàu kinh nghiệm với hơn 15 năm kinh nghiệm. Ông được hội đồng chứng nhận về y học cấp cứu và có chuyên môn đặc biệt trong việc chữa trị ung thư.',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
    category: 'Khoa cấp cứu',
    href: '/doctors',
  },
  {
    imgUrl: '/images/doctors/doctor_3.png',
    department: 'Khoa cấp cứu',
    name: 'Bác sĩ Karen Lee',
    designation: 'Bác sĩ Cấp cứu',
    description:
      'Bác sĩ Lee là một bác sĩ cấp cứu có tay nghề cao, có chuyên môn về điều trị các tình trạng bệnh lý cấp tính. Cô ấy được hội đồng chứng nhận về y học cấp cứu và có mối quan tâm đặc biệt đến tim mạch.',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
    category: 'Khoa cấp cứu',
    href: '/doctors',
  },
  {
    imgUrl: '/images/doctors/doctor_4.png',
    department: 'Khoa nhi',
    name: 'Bác sĩ Thúy My',
    designation: 'Khoa nhi',
    description:
      'Bác sĩ Thúy My là một bác sĩ nhi khoa  với hơn 15 năm kinh nghiệm. Cô được hội đồng chứng nhận về nhi khoa và có mối quan tâm đặc biệt đến việc chăm sóc phòng ngừa và phát triển trẻ nhỏ.',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
    category: 'Khoa nhi',
    href: '/doctors',
  },
  {
    imgUrl: '/images/doctors/doctor_5.png',
    department: 'Khoa nhi',
    name: 'Bác sĩ Cao Lân',
    designation: 'Khoa nhi',
    description:
      'Bác sĩ Lân là một bác sĩ nhi khoa có tay nghề cao, có chuyên môn về điều trị các bệnh, tình trạng thông thường ở trẻ em và được hội đồng chứng nhận về nhi khoa.',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
    category: 'Khoa nhi',
    href: '/doctors',
  },
  {
    imgUrl: '/images/doctors/doctor_6.png',
    department: 'Khoa nhi',
    name: 'Bác sĩ Anh Thy',
    designation: 'Khoa nhi',
    description:
      'Bác sĩ Thy là một bác sĩ nhi khoa tận tâm với dịch vụ chăm sóc toàn diện cho trẻ em ở mọi lứa tuổi. Cô được hội đồng chứng nhận về nhi khoa và có mối quan tâm đặc biệt đến bệnh béo phì ở trẻ em.',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
    category: 'Khoa nhi',
    href: '/doctors',
  },
  {
    imgUrl: '/images/doctors/doctor_7.png',
    department: 'Khoa tim mạch',
    name: 'Bác sĩ Jake Fill',
    designation: 'Khoa tim mạch',
    description:
      'Bác sĩ Fill là bác sĩ tim mạch lành nghề, có chuyên môn về chẩn đoán và điều trị bệnh tim mạch. Ông được hội đồng chứng nhận về tim mạch và có mối quan tâm đặc biệt đến tim mạch phòng ngừa.',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
    category: 'Khoa tim mạch',
    href: '/doctors',
  },
  {
    imgUrl: '/images/doctors/doctor_8.png',
    department: 'Khoa tim mạch',
    name: 'Bác sĩ John Smith',
    designation: 'Khoa tim mạch',
    description:
      'Bác sĩ Smith là bác sĩ tim mạch được hội đồng chứng nhận với nhiều kinh nghiệm trong việc quản lý các tình trạng tim phức tạp. Ông có mối quan tâm đặc biệt đến tim mạch can thiệp.',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
    category: 'Khoa tim mạch',
    href: '/doctors',
  },
  {
    imgUrl: '/images/doctors/doctor_9.png',
    department: 'Khoa tâm lý',
    name: 'Bác sĩ Sarah Lee',
    designation: 'Khoa tâm lý',
    description:
      'Với hơn 15 năm kinh nghiệm tìm hiểu tâm lý và hành vi con người, Tiến sĩ Lee là chuyên gia về điều trị rối loạn tâm trạng và rối loạn lo âu',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
    category: 'Khoa tâm lý',
    href: '/doctors',
  },
  {
    imgUrl: '/images/doctors/doctor_10.png',
    department: 'Khoa tâm lý',
    name: 'Bác sĩ David Park',
    designation: 'Khoa tâm lý',
    description:
      'Bác sĩ Park là bác sĩ tâm lý được hội đồng chứng nhận với hơn 10 năm kinh nghiệm. Ông có mối quan tâm đặc biệt đến tâm thần học cai nghiện và điều trị rối loạn sử dụng chất gây nghiện.',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
    category: 'Khoa tâm lý',
    href: '/doctors',
  },
  {
    imgUrl: '/images/doctors/doctor_11.png',
    department: 'Khoa tâm lý',
    name: 'Bác sĩ Maria Rose',
    designation: 'Khoa tâm lý',
    description:
      'Bác sĩ Rose là một bác sĩ tâm lý lành nghề có chuyên môn về điều trị rối loạn tâm trạng và rối loạn tâm thần. Cô được hội đồng chứng nhận về tâm lý học và có mối quan tâm đặc biệt đến việc quản lý sức khỏe tinh thần.',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
    category: 'Khoa tâm lý',
    href: '/doctors',
  },
  {
    imgUrl: '/images/doctors/doctor_12.png',
    department: 'Khoa Phụ sản ',
    name: 'Bác sĩ Alist Kim',
    designation: 'Khoa Phụ sản ',
    description:
      'Bác sĩ Kim đã có 10 năm kinh nghiệm trong chẩn đoán và điều trị các căn bệnh liên quan đến phụ khoa và theo dõi phụ sản.',
    social: [
      { icon: 'fa6-brands:facebook-f', href: '/about' },
      { icon: 'fa6-brands:linkedin-in', href: '/about' },
      { icon: 'fa6-brands:twitter', href: '/about' },
    ],
    category: 'Khoa Phụ sản',
    href: '/doctors',
  },
];

export default function Doctors() {
  pageTitle('Doctors');
  return (
    <>
      <BannerSectionStyle5
        bgUrl="/images/doctors/banner_bg.svg"
        imgUrl="/images/doctors/banner_img.png"
        title="Giới thiệu bạn<br/>Chuyên gia của chúng tôi"
        subTitle="Danh sách bác sĩ được cấp chứng chỉ có nhiều năm kinh nghiệm chuyên môn"
      />
      <Section topMd={65} bottomMd={200} bottomLg={150} bottomXl={110}>
        <TeamSectionStyle2 data={teamData} />
      </Section>
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle4
          bgUrl="images/doctors/banner_bg_2.jpeg"
          title="VÌ MỘT VIỆT NAM AN TÂM,<br/> KHOẺ MẠNH!"
          subTitle="Lên lịch khám với 1 trong những bác sĩ có chuyên môn ngay hôm nay!"
        />
      </Section>
    </>
  );
}
