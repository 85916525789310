import { useEffect } from 'react';

const AIChatbox = () => {
  useEffect(() => {
    const loadWatsonAssistantScript = () => {
      window.watsonAssistantChatOptions = {
        integrationID: "44f59ca6-08a8-4673-81fb-35508bca352f",
        region: "jp-tok",
        serviceInstanceID: "49257aff-4a18-4dbd-b753-755e430cf045",
        onLoad: async (instance) => {
          try {
            await instance.render()
            const hideBranding = () => {
              const brandingElement = document.querySelector('[title="Built with IBM Watson"]');
              if (brandingElement) {
                brandingElement.style.opacity = '0';
              }
            };
            hideBranding();
            const observer = new MutationObserver(() => {
              hideBranding();
            });

            observer.observe(document.body, { childList: true, subtree: true });

          } catch (error) {
            console.error('Error initializing Watson Assistant session:', error);
          }
        }
      };

      const existingScript = document.getElementById('watsonAssistantChatScript');
      if (!existingScript) {
        const script = document.createElement('script');
        script.src = "https://web-chat.global.assistant.watson.appdomain.cloud/versions/latest/WatsonAssistantChatEntry.js";
        script.id = 'watsonAssistantChatScript';
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
      }
    };

    loadWatsonAssistantScript();
  }, []);

  return null;
};

export default AIChatbox;
