import React from 'react';
import BannerSectionStyle5 from '../Section/BannerSection/BannerSectionStyle5';
import Section from '../Section';
import DepartmentSectionStyle3 from '../Section/DepartmentSection/DepartmentSectionStyle3';
import BannerSectionStyle6 from '../Section/BannerSection/BannerSectionStyle6';
import { pageTitle } from '../../helpers/PageTitle';
const departmentData = [
  {
    iconUrl: 'images/departments/icon_1.svg',
    title: 'Khoa cấp cứu',
    subTitle:
      'Khoa này cung cấp dịch vụ chăm sóc y tế ngay lập tức cho những bệnh nhân mắc bệnh cấp tính hoặc thương tích cần được chăm sóc ngay lập tức.',
    href: '/departments',
  },
  {
    iconUrl: 'images/departments/icon_1.svg',
    title: 'Khoa tim mạch',
    subTitle:
      'Khoa tim mạch chuyên cung cấp các dịch vụ chẩn đoán và điều trị các bệnh liên quan đến tim và hệ tuần hoàn. Chúng tôi áp dụng công nghệ tiên tiến và phương pháp điều trị hiện đại để chăm sóc bệnh nhân mắc các bệnh như cao huyết áp, suy tim, bệnh mạch vành, và rối loạn nhịp tim.',
    href: '/departments',
  },
  {
    iconUrl: 'images/departments/icon_1.svg',
    title: 'Khoa Nhi',
    subTitle:
      'Khoa Nhi tập trung vào chăm sóc sức khỏe toàn diện cho trẻ em từ sơ sinh đến tuổi vị thành niên. Chúng tôi cung cấp các dịch vụ khám, điều trị bệnh nhi khoa, tư vấn dinh dưỡng, tiêm chủng và theo dõi sự phát triển của trẻ, đảm bảo môi trường an toàn và thân thiện cho các bé.',
    href: '/departments',
  },
  {
    iconUrl: 'images/departments/icon_1.svg',
    title: 'Sản khoa và phụ khoa',
    subTitle:
      'Sản khoa và phụ khoa chuyên về chăm sóc sức khỏe sinh sản cho phụ nữ, bao gồm dịch vụ khám thai, chăm sóc trước và sau sinh, điều trị các bệnh phụ khoa, và hỗ trợ sinh sản. Đội ngũ bác sĩ giàu kinh nghiệm của chúng tôi cam kết mang đến sự chăm sóc tốt nhất cho mẹ và bé trong suốt thai kỳ và sau sinh.',
    href: '/departments',
  },
  {
    iconUrl: 'images/departments/icon_1.svg',
    title: 'Khoa tâm lý',
    subTitle:
      'Khoa tâm lý cung cấp các dịch vụ tư vấn và điều trị các vấn đề về tâm lý và sức khỏe tinh thần. Chúng tôi hỗ trợ bệnh nhân đối phó với căng thẳng, lo âu, trầm cảm, và các rối loạn tâm lý khác thông qua các phương pháp trị liệu cá nhân, nhóm và gia đình.',
    href: '/departments',
  },
  {
    iconUrl: 'images/departments/icon_1.svg',
    title: 'Khoa thần kinh',
    subTitle:
      'Khoa thần kinh chuyên về chẩn đoán và điều trị các rối loạn liên quan đến hệ thần kinh, bao gồm bệnh Parkinson, động kinh, đột quỵ, và các rối loạn thần kinh khác. Chúng tôi sử dụng các kỹ thuật hiện đại và liệu pháp tiên tiến để cung cấp sự chăm sóc toàn diện cho bệnh nhân.',
    href: '/departments',
  },
  {
    iconUrl: 'images/departments/icon_1.svg',
    title: 'Khoa vật lý trị liệu',
    subTitle:
      'Khoa vật lý trị liệu cung cấp các dịch vụ phục hồi chức năng cho bệnh nhân sau chấn thương, phẫu thuật, hoặc mắc các bệnh lý mạn tính. Chúng tôi áp dụng các phương pháp vật lý trị liệu và trị liệu bằng tay để giúp bệnh nhân cải thiện khả năng vận động, giảm đau và nâng cao chất lượng cuộc sống.',
    href: '/departments',
  },
];

export default function Departments() {
  pageTitle('Dịch vụ');
  return (
    <>
      <BannerSectionStyle5
        bgUrl="/images/departments/banner_bg.svg"
        imgUrl="/images/departments/banner_img.png"
        title="Làm quen với <br>Dịch vụ của Yuumi"
        subTitle="Tại Yuumi, chúng tôi cung cấp nhiều loại dịch vụ y tế và chăm sóc sức khỏe được thiết kế để đáp ứng nhu cầu cá nhân của bạn và giúp bạn đạt được sức khỏe tối ưu."
      />
      <Section bottomMd={140} bottomLg={100} bottomXl={60}>
        <DepartmentSectionStyle3 data={departmentData} />
      </Section>
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle6
          imgUrl="/images/departments/banner_img_2.png"
          title="VÌ MỘT VIỆT NAM AN TÂM, KHỎE MẠNH!"
          subTitle="Lên lịch khám với một trong những người <br />có kinh nghiệm của chúng tôi ngay hôm nay!"
        />
      </Section>
    </>
  );
}
